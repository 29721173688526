
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
  reactive,
  nextTick,
} from "vue";
import Header from "@/components/header/index.vue";
import Tail from "@/components/tail/index.vue";
import Slide from "@/components/slide/index.vue";
import { useRouter } from "vue-router";
import store from "@/store";
import { resizeBus } from "@/helper";

const cbbc = require("../assets/imgs/pay.png");
const mining = require("../assets/imgs/globpay.png");
const app = require("../assets/imgs/bank.png");

export default defineComponent({
  name: "Home",
  components: { Header, Slide, Tail },
  setup() {
    const bkcolor = ref("");
    const router = useRouter();
    const data = reactive({
      app,
      cbbc,
      mining,
    });
    const winScroll = () => {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 80) {
        bkcolor.value = "dark";
      } else {
        bkcolor.value = "";
      }
      if (scrollTop >= tops[1] - 300) {
        showapp.value = false;
      } else {
        showapp.value = true;
      }
      if (scrollTop >= tops[1] - 300 && scrollTop <= tops[2] - 300) {
        showcbdc.value = true;
      } else {
        showcbdc.value = false;
      }
      if (scrollTop >= tops[2] - 300) {
        showmin.value = true;
      } else {
        showmin.value = false;
      }
    };
    const dombank = ref(null);
    const domremit = ref(null);
    const dompay = ref(null);
    const tops = [0, 0, 0];
    onMounted(() => {
      window.addEventListener("scroll", winScroll);
      const top1 = window.scrollY;
      let dom: any = dombank.value;
      tops[0] = dom.getBoundingClientRect().top - 50;
      dom = domremit.value;
      tops[1] = dom.getBoundingClientRect().top - 50;
      dom = dompay.value;
      tops[2] = dom.getBoundingClientRect().top - 70;
      console.log(tops[0]);
      store.commit("ui/setRisn", tops);
      resizeBus.add("risn", () => {
        clientH.value = document.documentElement.clientHeight;
      });
    });
    onBeforeUnmount(() => {
      resizeBus.remove("risn");
      window.removeEventListener("scroll", winScroll);
    });
    const showapp = ref(true);
    const showcbdc = ref(false);
    const showmin = ref(false);
    const showimg = computed(() => {
      return showapp.value || showcbdc.value || showmin.value;
    });
    const gotolink = (url: string, exter = false) => {
      if (exter) {
        window.location.href = url;
        return;
      }
      router.push(url);
    };
    const idx = ref(0);
    const handchange = (num: number) => {
      idx.value = num;
    };
    const side = ref(null);
    const gotoside = (index = 0) => {
      idx.value = index;
      if (side.value) {
        (side.value as any).gotoside(index);
      }
    };
    const clientH = ref(0);
    clientH.value = document.documentElement.clientHeight;
    const divt = computed(() => {
      if (clientH.value > 680) {
        return (clientH.value - 680) / 2;
      }
      return 0;
    });
    return {
      divt,
      gotoside,
      side,
      dombank,
      domremit,
      dompay,
      handchange,
      idx,
      gotolink,
      data,
      showimg,
      bkcolor,
      showapp,
      showcbdc,
      showmin,
    };
  },
});
